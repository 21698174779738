import './style.css';
import * as React from "react";
import Navigation from '../../components/Navigation';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <p>
          Здесь будет мой диплом.
        </p>

      </header>
      <main>
        Приложение для инцидентов
      </main>
    </div>
  );
}

export default Home;
