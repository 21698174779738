import * as React from "react";
import Navigation from "../../components/Navigation";
import "./style.css";

function Archive() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <p>
          Список всех инцидентов
        </p>

      </header>
      <main>
        
      </main>
    </div>
  );
}

export default Archive;
