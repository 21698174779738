import { Link } from "react-router-dom";
import './style.css';

function Navigation() {
    return (
        <nav className="navigation">
            <ul className="navigation_list">
                <li><Link to="/">Главная</Link></li>
                <li>
                    <Link to="/open">Открыть инцидент
                    </Link></li>
                <li>
                    <Link to="/archive">Архив
                    </Link></li>
            </ul>
        </nav>
    );
}

export default Navigation;