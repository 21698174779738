import * as React from "react";
import Navigation from "../../components/Navigation";
import "./style.css"

function Open() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <p>
          Открыть инцидент
        </p>

      </header>
      <main>
        По бырому
      </main>
    </div>
  );
}

export default Open;
